// ExternalUrlSlide.js
import React from "react";

function ExternalUrlSlide({ url, slideNo }) {
  return (
    <div>
      <img
        src={url}
        alt={`Slide ${slideNo}`}
        style={{ width: "100%", height: "auto" }}
      />
    </div>
  );
}

export default ExternalUrlSlide;
