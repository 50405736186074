import React from "react";
const ConsoleLogger = () => {
  console.log(`
__      __        _                            _                                                    
\\ \\    / /  ___  | |  __   ___   _ __    ___  | |                                                   
 \\ \\/\\/ /  / -_) | | / _| / _ \\ | '  \\  / -_) |_|                                                   
  \\_/\\_/   \\___| |_| \\__| \\___/ |_|_|_| \\___| (_)                                                   
                                                              
__   __  _  _   ___  __      __    
\\ \\ / / | \\| | / __| \\ \\    / /   
 \\ V /  | .\` | \\__ \\  \\ \\/\\/ /   
  \\_/   |_| \_| |___/   \\_/\\_/     

 __  __                      
|  \\/  |  ___   _ _    _  _   ___
| |\\/| | / -_) | ' \\  | || | (_-<
|_|  |_| \\___| |_||_|  \\_,_| /__/
                                  

                              
`);

  console.log("Github Build ID:", process.env.REACT_APP_BUILD_ID);

  return null;
};

export default ConsoleLogger;
