// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SlideBuilder from "./SlideBuilder";
import Home from "./Home";
import "./App.css";

function App() {
  return (
    <Router>
      <Routes>
        {/* Define the route with the 'ip' parameter */}
        <Route path="/:ip" element={<SlideBuilder />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
