// ErrorPage.js
import React from "react";

function ErrorPage({ error, ip }) {
  const pageStyle = {
    width: "1920px",
    height: "1080px",
    backgroundColor: "black",
    color: "white",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const logoStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "800px",
    maxHeight: "600px",
  };

  const errorTextStyle = {
    position: "absolute",
    bottom: "20px",
    left: "20px",
    fontSize: "20px",
  };

  const ipTextStyle = {
    position: "absolute",
    bottom: "20px",
    right: "20px",
    fontSize: "20px",
  };

  return (
    <div style={pageStyle}>
      <img src="merivale.png" alt="Logo" style={logoStyle} />
      <div style={errorTextStyle}>Error: {error}</div>
      <div style={ipTextStyle}>IP: {ip}</div>
    </div>
  );
}

export default ErrorPage;
