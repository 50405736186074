import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ImageSlide from "./components/ImageSlide";
import TextSlide from "./components/TextSlide";
import ExternalUrlSlide from "./components/ExternalUrl";
import ExternalVideoSlide from "./components/ExternalVideo";
import Loading from "./components/Loading";
import ErrorPage from "./components/ErrorPage";
import Promotion from "./components/Promotion";
import ClosedSlide from "./components/ClosedSlide";
import useWebSocket from "./hooks/useWebSocket";

function SlideBuilder() {
  const { ip } = useParams();
  const [slides, setSlides] = useState([]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [brand, setBrand] = useState(null);
  const [brandColours, setBrandColours] = useState(null);
  const [darkmode, setDarkmode] = useState(null);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [promotion, setPromotion] = useState({ active: false });
  const [venue, setVenue] = useState(null);
  const [openTime, setOpenTime] = useState(null);
  const [statusmessage, setStatusMessage] = useState(null);
  const [surchargeMessage, setSurchargeMessage] = useState(null);

  useEffect(() => {
    const fetchSurchargeMessage = async () => {
      try {
        const response = await fetch("/api/config/surcharge");
        const data = await response.json();
        console.log("response", data.surchargemessage);
        setSurchargeMessage(data.surchargemessage);
      } catch (error) {
        console.error("Error fetching surcharge message:", error);
        setSurchargeMessage("");
      }
    };

    fetchSurchargeMessage();
  }, []);

  const fetchSlides = async () => {
    try {
      const response = await fetch(`/api/slidebuilder/${ip}`);
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to fetch slides data.");
      }

      const slidesArray = Array.isArray(data.slides)
        ? data.slides
        : Object.values(data.slides);

      if (slidesArray.length > 0) {
        setSlides(slidesArray);
        setBrand(data.brand);
        setDarkmode(data.darkmode);
        setVenue(data.venue);
        setOpenTime(data.openTime);
        setStatusMessage(data.statusmessage);

        if (data.brand) {
          const brandResponse = await fetch(`/api/theme/${data.brand}`);
          const brandData = await brandResponse.json();

          if (!brandResponse.ok) {
            throw new Error(
              brandData.error || "Failed to fetch brand options."
            );
          }

          setBrandColours(brandData.theme);
        }
      } else {
        setSlides([]);
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setError(error.message);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
  };

  useEffect(() => {
    fetchSlides();
  }, [ip]);

  useWebSocket(ip, setDarkmode, setPromotion, fetchSlides);

  useEffect(() => {
    if (slides.length > 0 && !videoPlaying) {
      const interval = slides[currentSlideIndex].interval || 5000;

      const timer = setTimeout(() => {
        setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
      }, interval);

      return () => clearTimeout(timer);
    }
  }, [currentSlideIndex, slides, videoPlaying]);

  const handleVideoEnd = () => {
    setVideoPlaying(false);
    setCurrentSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  if (loading) return <Loading />;
  if (error) return <ErrorPage error={error} ip={ip} />;
  if (!slides.length) return <div>No slides available</div>;

  const backgroundColor = darkmode === "light" ? "#f5f5f5" : "#333333";

  return (
    <div
      style={{
        position: "relative",
        width: "1920px",
        height: "1080px",
        overflow: "hidden",
        backgroundColor: backgroundColor,
      }}
    >
      <Promotion promotion={promotion} />

      {slides.map((slide, index) => (
        <div
          key={index}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: index === currentSlideIndex ? 1 : 0,
            transition:
              index === currentSlideIndex ? "opacity 0s ease" : "none",
          }}
        >
          {(slide.type === "closed" || slide.type === "preopen") && (
            <ClosedSlide
              status={slide.type}
              venue={venue}
              brand={brand}
              brandColours={brandColours}
              darkmode={darkmode}
              openTime={openTime}
              statusmessage={statusmessage}
            />
          )}
          {slide.type === "image" && (
            <ImageSlide
              products={slide.products}
              brand={brand}
              brandColours={brandColours}
              darkmode={darkmode}
              surchargeMessage={surchargeMessage}
            />
          )}
          {slide.type === "text" && (
            <TextSlide
              products={slide.products}
              brand={brand}
              brandColours={brandColours}
              darkmode={darkmode}
              surchargeMessage={surchargeMessage}
            />
          )}
          {slide.type === "external" && (
            <ExternalUrlSlide
              url={slide.externalimgurl}
              slideNo={slide.slide_no}
            />
          )}
          {slide.type === "video" && (
            <ExternalVideoSlide
              url={slide.videoUrl}
              isActive={index === currentSlideIndex}
              onPlay={() => setVideoPlaying(true)}
              onEnd={handleVideoEnd}
            />
          )}
        </div>
      ))}
    </div>
  );
}

export default SlideBuilder;
