// ./components/Promotion.js
import React from "react";

function Promotion({ promotion }) {
  if (!promotion || !promotion.active) {
    return null; // Render nothing if no active promotion
  }

  const { type, url } = promotion;

  const promotionStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.1)", // Semi-transparent overlay
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10, // Ensure it stays on top
  };

  return (
    <div style={promotionStyle}>
      {type === "image" && (
        <img
          src={url}
          alt="Promotion"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      )}
      {type === "video" && (
        <video
          src={url}
          autoPlay
          loop
          muted
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      )}
      {type === "gif" && (
        <img
          src={url}
          alt="Promotion GIF"
          style={{ maxWidth: "100%", maxHeight: "100%" }}
        />
      )}
    </div>
  );
}

export default Promotion;
