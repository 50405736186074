import React from "react";

const apronSidebarStyle = {
  width: "20%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
};

const apronImageStyle = {
  maxHeight: "100%",
  objectFit: "contain",
};

function ImageSlide({
  products,
  brand,
  darkmode,
  brandColours = {},
  surchargeMessage,
}) {
  const apronImageUrl = `https://vnswstaticcontent.z8.web.core.windows.net/${brand}.jpg`;
  const backgroundColor = darkmode === "light" ? "#f5f4ee" : "#333333";
  const textColor = darkmode === "light" ? "#333" : "#fff";
  const primaryColor = brandColours.primary_hex || "#1e90ff";

  const productGridStyle = {
    display: "grid",
    width: "80%",
    padding: "2rem",
    gap: "3rem",
    gridTemplateColumns: "1fr 1fr",
    justifyContent: products.length <= 2 ? "center" : "unset",
    position: "relative",
  };

  const bottomRightMessageStyle = {
    position: "absolute",
    bottom: "2rem",
    right: "2rem",
    fontStyle: "italic",
    fontSize: "24px",
    color: primaryColor,
    ...(darkmode === "dark" && {
      color: "#fff",
    }),
  };

  const dividerStyle = {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: "50%",
    width: "2px",
    backgroundColor: darkmode === "dark" ? "#444" : "#ccc",
  };

  const productCardStyle = {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    alignItems: "center",
    padding: "1rem 0",
    minHeight: "400px",
    maxHeight: "400px",
  };

  const productImageStyle = {
    gridColumn: "1 / -1",
    width: "100%",
    height: "auto",
    minHeight: "250px",
    minWidth: "500px",
    maxWidth: "700px",
    maxHeight: "350px",
    marginBottom: "1rem",
    objectFit: "contain",
  };

  const productNameStyle = {
    textAlign: "left",
    fontFamily: "Athletics-Medium, sans-serif",
    fontSize: "48px",
    lineHeight: "1.2",
    overflow: "hidden",
    whiteSpace: "pre-wrap",
    color: textColor,
  };

  const productPriceStyle = {
    textAlign: "right",
    fontFamily: "Athletics-BOLD, sans-serif",
    fontSize: "48px",
    color: primaryColor,
    width: "100px",
    marginLeft: "1rem",
    ...(darkmode === "dark" && {
      color: "#fff",
    }),
  };

  return (
    <div
      style={{
        display: "flex",
        width: "1920px",
        height: "1080px",
        backgroundColor: backgroundColor,
      }}
    >
      {/* Apron Sidebar */}
      <div style={apronSidebarStyle}>
        <img src={apronImageUrl} alt="Apron" style={apronImageStyle} />
      </div>

      {/* Product Grid with Center Divider */}
      <div style={productGridStyle}>
        {/* <div style={dividerStyle}></div>  */}
        {products.map((product, index) => (
          <div key={index} style={productCardStyle}>
            <img
              src={product.Image}
              alt={product.DisplayName}
              style={productImageStyle}
            />
            <span style={productNameStyle}>{product.DisplayName}</span>
            <span style={productPriceStyle}>{product.Price}</span>
          </div>
        ))}
      </div>

      {/* Bottom Right Message */}
      <div style={bottomRightMessageStyle}>{surchargeMessage}</div>
    </div>
  );
}

export default ImageSlide;
