import { useEffect } from "react";

const useWebSocket = (ip, setDarkmode, setPromotion, fetchSlides) => {
  useEffect(() => {
    const ws = new WebSocket(
      `${window.location.origin.replace(/^http/, "ws")}/api/liveupdate`
    );

    const pingInterval = 30000; // 30 seconds

    // Function to send a ping message
    const sendPing = () => {
      if (ws.readyState === WebSocket.OPEN) {
        ws.send(JSON.stringify({ type: "ping" }));
      }
    };

    // Set an interval to send pings
    const pingIntervalId = setInterval(sendPing, pingInterval);

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);

      // Handle different types of WebSocket messages
      if (message.update) {
        fetchSlides(); // Re-fetch slides if there's an update
      }
      if (message.darkmode) {
        setDarkmode(message.darkmode); // Set darkmode state
      }
      if (message.promotion) {
        setPromotion(message.promotion); // Set Promotion state
      }
    };

    ws.onclose = () => {
      clearInterval(pingIntervalId); // Clear the ping interval if connection closes
    };

    return () => {
      ws.close();
      clearInterval(pingIntervalId);
    };
  }, [ip, setDarkmode, setPromotion, fetchSlides]);
};

export default useWebSocket;
