import React, { useEffect, useRef } from "react";

function ExternalUrlSlide({ url, slideNo, isActive, onPlay, onEnd }) {
  const isVideo = url.includes(".mp4"); // Check if URL points to a video
  const videoRef = useRef(null);

  useEffect(() => {
    if (isVideo && videoRef.current) {
      if (isActive) {
        videoRef.current
          .play()
          .then(() => onPlay && onPlay()) // Notify parent when video starts
          .catch((error) => console.error("Video playback failed:", error));
      } else {
        videoRef.current.pause();
        videoRef.current.currentTime = 0; // Reset video when inactive
      }
    }
  }, [isActive, onPlay, isVideo]);

  return (
    <div style={{ width: "100%", height: "100%", backgroundColor: "black" }}>
      {isVideo ? (
        <video
          ref={videoRef}
          onEnded={onEnd} // Trigger onEnd callback to move to the next slide
          src={url}
          autoPlay // Ensure autoPlay is directly on the element
          muted
          playsInline
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      ) : (
        <img
          src={url}
          alt={`Slide ${slideNo}`}
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      )}
    </div>
  );
}

export default ExternalUrlSlide;
