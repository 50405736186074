import React from "react";
import "./Loading.css"; // Custom CSS for styling

const Loading = () => {
  return (
    <div className="loading-container">
      <img
        src={`${process.env.PUBLIC_URL}/vdotgrn.png`}
        alt="Loading"
        className="vdot"
      />
    </div>
  );
};

export default Loading;
